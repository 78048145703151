<template>
  <div :style="{ backgroundImage: 'url(' + gifUrl + ')', minHeight: '100vh', backgroundSize: 'cover', backgroundPosition: 'center' }">
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',
  data() {
    return {
      gifUrl: '',
    };
  },
  created() {
    this.fetchRandomGif();
  },
  methods: {
    async fetchRandomGif() {
  const apiKey = process.env.VUE_APP_GIPHY_API_KEY;
  const apiUrl = `https://api.giphy.com/v1/gifs/random?api_key=${apiKey}`;
  try {
    const response = await axios.get(apiUrl);
    this.gifUrl = response.data.data.images.original.url; // Corrected line
  } catch (error) {
    console.error('Error fetching GIF:', error);
  }
},
  }
}
</script>

<style>
body {
  margin:0px;
}
</style>
